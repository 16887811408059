
.az-product-page {

    .az-product-banner {
        height: 230px;

        img {
            object-fit: cover;
            object-position: right;
        }
    }

    .az-product-sidebar {
        position: relative;
        z-index: 2;

        .az-product-img {
            img {
                max-height: 120px;
            }
        }

        .az-product-bookmark-btn {
            text-decoration: none;
        }
    }

    .az-product-screenshots {
        .carousel-item {
            overflow: hidden;

            &:after {
                display: block;
                margin-top: 65%;
                content: '';
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                height: 100%;
                object-fit: cover;
            }
        }

        .carousel-indicators {
            position: relative;

            li {
                width: 80px;
                height: 60px;
                border: 1px solid #ccc;

                img {
                    object-fit: cover;
                }

                &.active {
                    border-color: $link-color;
                }
            }
        }
    }

    .az-product-reviews {
        .az-product-review-user {
            flex: 0 0 180px;
        }
        .az-product-review-detail {
            margin-left: 20px;
        }

        .az-product-review-user-avatar {
            width: 80px;
            height: 80px;
            background: #f5f5f5;
            border: 1px solid #ccc;
            border-radius: 50%;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .az-product-review-user-info {
            p {
                margin: 0;
                font-size: .875rem;
                font-weight: 300;

                strong {
                    font-weight: 500;
                }

                .fas, .far {
                    width: 20px;
                }
            }
        }
    }

    .az-product-pricings {
        .card-body {
            p {
                font-weight: 300;
            }
        }
        .list-group .list-group-item {
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-weight: 300;
        }
    }
}

@media (max-width: 767.98px) {
    .az-product-page {
        .az-product-tabs {
            position: relative;

            .navbar-collapse {
                position: absolute;
                left: 0;
                top: 100%;
                right: 0;
                padding-top: 1px;
                z-index: 3;
            }

            .navbar-nav {
                background: #f5f5f5;
                border-left: 1px solid #dee2e6;
                border-right: 1px solid #dee2e6;
                box-shadow: 0 .25rem .5rem rgba(0, 0, 0, 0.15);

                .nav-item {
                    border-bottom: 1px solid #dee2e6;
                    .nav-link {
                        padding: 8px 15px;
                    }

                    &.active {
                        .nav-link {

                        }
                    }
                }
            }
        }

        .az-product-downloads {
            .card .card-header {
                border-bottom: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.125);
            }
        }

        .az-product-reviews {
            .az-product-review-user {

            }
            .az-product-review-detail {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    .az-product-page {
        .az-product-tabs {
            padding-top: 0;
            padding-bottom: 0;

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        padding: 10px 15px;
                        line-height: 27px;
                        border-bottom: 3px solid transparent;
                        text-transform: uppercase;
                    }

                    &.active {
                        .nav-link {
                            border-bottom-color: $link-color;
                        }
                    }
                }
            }
        }

        .az-product-sidebar {
            .az-product-img {
                img {
                    max-height: 150px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .az-product-page {
        .az-product-banner {
            height: 310px;
        }

        .az-product-tabs {
            border-bottom: 1px solid #dee2e6;

            .navbar-nav {
                margin-left: 280px;
            }
        }

        .az-product-sidebar {
            margin: -320px 0 3rem 1.5rem;
            width: 250px;

            .az-product-img {
                width: 250px;
                height: 250px;

                img {
                    max-height: none;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .az-product-page {
        .az-product-banner {
            height: 370px;
        }

        .az-product-tabs {
            .navbar-nav {
                margin-left: 330px;
            }
        }

        .az-product-sidebar {
            margin-top: -370px;
            width: 300px;

            .az-product-img {
                width: 300px;
                height: 300px;
            }
        }
    }
}
