
.btn-social-icon {
	min-width: 55px;
	color: #fff;
	
	&.btn-linkedin {
		background-color: #0077b5;
	}
	
	&.btn-facebook {
		background-color: #4267b2;
	}
	
	&.btn-google {
		background-color: #4285f4;
	}
}