.az-home-hero {
    background: #fff url(../img/hero-bg.jpg) no-repeat center;
    background-size: cover;

    h2 {
        font-size: 2.25rem;
        font-weight: 300;
    }

    h3 {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .marketing-item {
        .fa, .fas {
            font-size: 3.5rem;
        }

        p {
            margin: 0 auto;
            max-width: 300px;
        }
    }
}


.az-home-searchbox {
    &.text-center {
        max-width: 500px;
    }

    form {
        position: relative;

        .form-control {
            padding-left: $input-height;
            border-color: $input-focus-border-color;

            &.form-control-lg {
                padding-left: $input-height-lg;
            }
        }

        .fa-search {
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: $input-height;
            height: $input-height;
            line-height: $input-height;
            font-size: $input-font-size;
            color: #aaa;
            text-align: center;
            pointer-events: none;
            z-index: 2;
        }

        .form-control.form-control-lg + .fa-search {
            width: $input-height-lg;
            height: $input-height-lg;
            line-height: $input-height-lg;
            font-size: $input-font-size-lg;
        }

        .form-control:focus + .fa-search {
            color: $input-focus-border-color;
        }
    }
}

.az-home-why-us {
    .lead {
        font-size: 1.1rem;
    }
}

.az-home-clients {
    img {
        margin: 10px;
        max-width: 160px;
    }
}

.az-home-pop-cats {
    background-color: #f0f0f0;

    h3.font-weight-normal {
        strong {
            font-weight: 500;
        }
    }

    .list-unstyled {
        margin-bottom: 1.425rem;

        li {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .95rem;

            a {
                display: inline-block;
                color: #313335;
            }
        }
    }
}

.az-vendor-cta {
    .btn-lg {
        min-width: 160px;
    }
}

@media (min-width: 768px) {
    .az-home-hero {
        h2 {
            font-size: 3rem;
        }
    }
}
