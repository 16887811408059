
.az-categories-list {
    .list-unstyled {
        break-inside: avoid;
    }
}

.az-cat-products-list {
    .az-cat-product-item {
        margin-bottom: 1rem;

        .az-cat-product-img {
            width: 64px;
            height: 64px;
        }

        .az-cat-product-info {
            overflow: hidden;

            .az-cat-product-name {
                margin-bottom: 3px;
                font-size: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .az-cat-product-rating {
                font-size: .9rem;
            }
            .az-cat-product-rating-text {
                font-size: .9rem;
            }
        }
    }

    a.az-cat-product-item:hover {
        text-decoration: none;
        background: #fafafa;
    }

    &.az-cat-compare-list {
        .az-cat-product-item {
            .az-cat-product-desc {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .az-cat-product-compare {
                position: absolute;
                top: 20px;
                right: 20px;

                &.disabled {
                    pointer-events: none;
                }

                label {
                    cursor: pointer;
                    padding-left: 35px!important;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        background: #f8f8f8;
                        border-radius: 2px;
                        left: 10px;
                        top: 50%;
                        margin-top: -8px;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 11px;
                        height: 5px;
                        transition: all 0.2s;
                        left: 13px;
                        top: 50%;
                        margin-top: -3px;
                        border: 2px solid #17a2b8;
                        border-top: 0;
                        border-right: 0;
                        transform: rotate(-45deg);
                        opacity: 0;
                    }
                }

                input[type="checkbox"] {
                    &:checked + label:after {
                        opacity: 1;
                    }
                }
            }

            .btn-remove {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    &.az-cat-add-more-list {
        .az-cat-product-item {
            cursor: pointer;
        }
    }
}

.az-compare-drawer {
    ul {
        li {
            width: 40px;
            height: 40px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            button {
                position: absolute;
                right: -16px;
                top: -16px;
            }
        }
    }
}

.card {
    &.flex-1 {
        .card-body {
            flex: 1;
        }
    }
}

@media (min-width: 576px) {
    .az-categories-list {
        column-count: 2;
    }

    .az-categories-sidelist {
        .list-unstyled {
            column-count: 2;
        }
    }
}

@media (min-width: 768px) {
    .az-categories-list {
        column-count: 3;
    }

    .az-cat-products-list {
        &.az-cat-compare-list {
            .az-cat-product-item {
                .az-cat-product-img {
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }

    .az-categories-sidelist {
        .list-unstyled {
            column-count: 3;
        }
    }
}

@media (min-width: 992px) {
    .az-categories-sidelist {
        .list-unstyled {
            column-count: 1;
        }
    }

    .az-categories-sidebar {
        width: 250px;
    }
}

@media (max-width: 767px) {
    .az-cat-products-list {
        &.az-cat-compare-list {
            .az-cat-product-item {
                .az-cat-product-compare {
                    left: 20px;
                    bottom: 20px;
                    right: auto;
                    top: auto;
                }
            }
        }
    }
}
