
.az-footer {
    h5.border-bottom {
        font-weight: normal;
        color: #f5f5f5;
    }

    .list-unstyled {
        li {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .95rem;

            a {
                display: inline-block;
                color: #f8f8f8;

                &.text-info {
                    font-weight: 500;
                }
            }
        }
    }
}
