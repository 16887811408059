
.form-control {
  box-shadow: none;
}

select.form-control[size], select.form-control[multiple] {
    -webkit-appearance: unset;
}

select.az-chained-select:disabled {
	display: none;
}

.selected-cats .badge {
    float: left;
    position: relative;
    margin: 0 5px 5px 0;
    padding: 7px 30px 7px 14px;
    font-size: 16px;
    line-height: 22px;
    max-width: calc(50% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
        position: absolute;
        right: 10px;
        top: 3px;
    }

    &#add_category_toggle {
        padding: 7px 10px;
    }
}

.selected-features .badge {
    float: left;
    position: relative;
    margin: 0 5px 5px 0;
    padding: 7px 7px 7px 30px;
    font-size: 16px;
    line-height: 22px;
    max-width: calc(50% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    input {
        display: none;
    }

    .fa-square {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    input:checked + .fa-square {
        &:before {
            content: "\F14A";
        }
    }
}
